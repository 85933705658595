<template>
  <div class="article">
    <div class="title">{{ info.articleName }}</div>
    <div class="sub-title">{{ info.articleSubtitle }}</div>
    <div class="catalog">{{ info.oneCategoryName }}|{{ info.twoCategoryName }}</div>
    <div class="article-content" v-html="info.content"></div>
  </div>
</template>

<script>
import {getArticle} from '@/api/product'

export default {
  data() {
    return {
      id: undefined,
      info: {
        articleName: undefined,
        articleSubtitle: undefined,
        oneCategoryName: undefined,
        twoCategoryName: undefined,
        content: undefined
      }
    }
  },
  computed: {},
  components: {},
  created() {
    this.id = this.$route.params.id
    this.doQuery()
  },
  methods: {
    doQuery() {
      getArticle(this.id).then((res) => {
        this.info = res.data
      }).catch(() => {
        this.$toast.fail("数据加载失败");
      })
    }
  }
}
</script>

<style lang="less" scoped>
.article {
  margin: 10px;
  background-color: #FFFFFF;
}

.title {
  color: #172B4D;
  font-size: 16px;
}

.sub-title {
  color: #172B4D;
  font-size: 14px;
  margin-top: 10px;
}
.catalog{
  color: #172B4D;
  font-size: 12px;
  margin: 15px 0;
}
/deep/ .article-content img {
  max-width: 100%;

}
</style>
