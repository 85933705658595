import request from '@/utils/request'

export function getArticle(id) {
    return request({
        url: '/app-api/app/web/detail/' + id,
        method: 'post',
        type: 'f'
    })
}

export function getProtocol(data) {
    return request({
        url: '/app-api/app/web/protocol',
        method: 'post',
        type: 'f',
        params: data
    })
}







